import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import axios from 'axios'

function Contact2() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  let navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [msgContent, setMsgContent] = useState('')
  const [termsAndConditions, setTermsAndConditions] = useState(false)

  const submitHandler = async (e) => {
    e.preventDefault()
    
    const data = { 'name': name, 'email': email,  'address': address,  'phone': phone, 'content': msgContent }
    try {
      const config = {
      headers: {
          'Content-type': 'application/json'
      }
      }
      console.log(data)

      navigate('/danku')
    const { response } = await axios.post(
    '/api/contact/',
    data,
    config
    )
    }
    catch(error) {
      console.log(error)
    }
  }

  return (
    <div class="card mb-3 border-light">
        <div class="card-body text-center" style={{fontSize:'125%'}}>
          <h3 class="card-title py-3">CONTACT</h3>
          <p>Heb je vragen of wil je graag meer informatie? Neem dan gerust vrijblijvend contact op via onderstaand formulier. We voorzien je zo snel mogelijk van een reactie.</p>
          
      <Form onSubmit={submitHandler} className='py-3'>

      <div class='row justify-content-md-center'>
        <div class="col-sm-6">
          <Form.Group controlId='email' className='pb-3'>
              <Form.Label>E-MAIL *</Form.Label>
              <Form.Control 
              required
              type='email' 
              placeholder='mail@example.nl' 
              onChange={(e) => setEmail(e.target.value)}
              >
              </Form.Control>
          </Form.Group>
        </div>  
        <div class="col-sm-6">
          <Form.Group controlId='address' className='pb-3'>
              <Form.Label>ADRES</Form.Label>
              <Form.Control 
              type='text' 
              placeholder='123 av. hello' 
              onChange={(e) => setAddress(e.target.value)}
              >
              </Form.Control>
          </Form.Group>
        </div>
      </div>
      <div class='row justify-content-md-center'>
        <div class="col-sm-6">
          <Form.Group controlId='phone' className='pb-3'>
              <Form.Label>TELEFOON</Form.Label>
              <Form.Control 
              type='number' 
              placeholder='+31612345678' 
              onChange={(e) => setPhone(e.target.value)}
              >
              </Form.Control>
          </Form.Group> 
        </div>
        <div class="col-sm-6">
          <Form.Group controlId='name'>
              <Form.Label>NAAM *</Form.Label>
              <Form.Control 
              required
              type='text' 
              placeholder='Jane Doe' 
              onChange={(e) => setName(e.target.value)}
              >
              </Form.Control>
          </Form.Group>
           
        </div>
        </div>
        <Form.Group controlId='msgContent' className='pb-3'>
              <Form.Label>BERICHT *</Form.Label>
              <Form.Control 
              required
              rows={5}
              as='textarea' 
              placeholder='' 
              onChange={(e) => setMsgContent(e.target.value)}
              >
              </Form.Control>
          </Form.Group>
          
          <Form.Group controlId='termsAndConditions' className='pb-3'>
              <Form.Check
              required
              label= {<p>Ik accepteer de <a href="/static/termsandconditions.html" target="_blank">algemene gebruiksvoorwaarden</a></p>}
              type='checkbox' 
              onChange={(e) => setTermsAndConditions(e.target.value)}
              >
              </Form.Check>
          </Form.Group>
          <Button type='submit' variant='outline-primary'>Verzenden</Button>
        </Form>

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9379.676608996771!2d6.221404331231747!3d52.77493242141092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c86d974a36499f%3A0x7c0a94f216e4cca6!2sDe%20Wolster!5e0!3m2!1sen!2sfr!4v1706554891813!5m2!1sen!2sfr" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Contact2
