import React from 'react'

function ImageTile({ src }) {
  return (
    <div className='tile'>
      <img loading='lazy' className='imgtile tile' src={src} />
    </div>
  )
}

export default ImageTile
