import React from 'react'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'


function TextTile({ title, content }) {
  return (
    <div className='tile text-tile py-5'>
      <h1>{title}</h1>
      <p style={{color:'black', fontSize:'50%', padding:'10px'}}><br/>{content}</p>
      <LinkContainer to='/overdewolster'>
        <Button  variant='outline-primary'>LEES MEER</Button>
      </LinkContainer>
    </div>
  )
}

export default TextTile
