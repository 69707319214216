import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ImageGallery from '../components/ImageGallery'
import { Row } from 'react-bootstrap'

function OverDewolster() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  const galleryImages = [
    {
      img: 'https://dewolster.nl/static/images/HomeScreenGallery1.jpg',
    },
    {
      img: 'https://dewolster.nl/static/images/HomeScreenGallery2.jpg',
    }
  ] 
  
  return (
    <div>
      <div>
      <div class="card mb-3 border-light">
        <div class="card-body text-center" style={{fontSize:'125%'}}>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Dé duurzame kledingwinkel voor jou</h4>
          <img loading='lazy' className='wrap-text-around-image mx-3' src='https://dewolster.nl/static/images/overdewolster1.jpg'/>
          <p class="card-text">Een jaar of 8 geleden is De Wolster bedacht door Mariska, de vorige eigenaresse. Zij droomde toen dat ze vanuit een bus wolletjes ging verkopen op de schoolpleinen van de Vrije Scholen. Hier in het Noorden is namelijk niet zo’n soort winkeltje, waar je de wolletjes kunt voelen en zien. En zij wilde heel graag de wolletjes naar de mensen brengen, om zo de wereld een beetje warmer te maken. Uiteindelijk heeft ze de stap gezet en een oude brandweerbus gekocht, baby- en kinderwolletjes ingeslagen, de bus van binnen verbouwd en toen kon ze op pad! Begonnen op het schoolplein van de Vrije School in Meppel, later ook Zwolle en Emmen.</p>
          <p class="card-text">Dat sloeg enorm aan. Er kwam steeds meer vraag naar ook dames- en herenwolletjes en ook biologische kinder- en dameskleding (van natuurlijke materialen). Zo ontstond het idee om ook een winkeltje aan huis te beginnen, zodat mensen daar op hun gemak kunnen voelen, passen en lekker koffie drinken. De vraag om op markten te komen staan met de Wolstertruck werd ook steeds groter. Sinds september 2018 ben ik Mariska gaan helpen in het winkeltje en op markten. Zij kregen steeds concretere plannen om een camping in het buitenland te kopen. En dat is begin 2019 gelukt…. de prachtige Natuurcamping Lagom in Grasmark, Zweden.</p>
          <p class="card-text">Toen kwam de vraag of ik De Wolster wilde overnemen! Dat vond ik een enorme eer en een mooi avontuur en dat ben ik aangegaan. Het was in het begin ook best spannend, ik had nog nooit een eigen bedrijf gehad en had ook nog een andere baan. Eerst kon ik het nog samen met Mariska doen, maar vanaf juli 2019 was De Wolster alleen van mij!</p>
            
          <p class="card-text">“We zijn verhuisd met het Wolsterwinkeltje naar Havelte, in het achter gedeelte van het huis van mijn ouders, op een prachtige plek. Mijn naam is Annemieke, ik ben 46 jaar en ik woon in Havelte met mijn gezin: mijn man en 4 kinderen. Met mijn andere baan ben ik gestopt, zodat ik mij helemaal kan richten op De Wolster. Dat doe ik met veel plezier en enthousiasme.</p>
          <p class="card-text">Ik vind het heerlijk om met al die mooie kleding te mogen werken, het contact te hebben met de mensen die komen, waardevolle gesprekken te hebben, een omhullend laagje te bieden door de mooie wolletjes die De Wolster verkoopt. Om nieuwe dingen voor in de collectie te zoeken, zoals de schapenvachten, maar ook nieuwe merken en aanvullende producten.</p>
          <p class="card-text">Op drukke, grote markten krijg ik gelukkig hulp van mijn lieve dochter en mijn man, dat is heel fijn om samen te doen. Ook in de winkel kun je ze af en toe tegen komen!</p>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Diverse merken</h4>
          <p class="card-text">In onze kledingwinkel vind je diverse artikelen van wol en andere natuurlijke materialen. De meeste merken die wij verkopen zijn GOTS-gecertificeerd, dit is het hoogste label voor groen textiel. Dit keurmerk stelt strenge eisen aan de productie van de vezels en de verdere verwerking hiervan tot kledingstukken. Hiermee ben je gegarandeerd van een duurzame en milieuvriendelijke productie.</p>
          <p class="card-text">Wij verkopen de volgende merken: Engel, Hocosa, Jalfe, Cosilana, Reiff, Disana, Tranquillo, Heidekönigin, Naturalmente & Dunque, Matona, Forgaminnt, Decolores, Hirsch, Grodo, Aran, Hust en Claire, Himalaya en Alkena. Nieuwe merken die er sinds dit jaar zijn: Alwero, Eribé, Heitmann Felle, Madness en Hempage.</p>
          {/* <Row> */}
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>De Wolstertruck</h4>
          <img loading='lazy' className='wrap-text-around-image mx-3' src='https://dewolster.nl/static/images/truck.jpg' style={{float:'left'}}/>
          <p class="card-text">Je kunt de Wolstertruck vinden op verschillende duurzame markten in Nederland. De oude, rode brandweerbus is een rijdend winkeltje en een groot gedeelte van het assortiment nemen we op deze manier mee naar de markten. We staan onder andere op het Wilde Weekend, op de voor- en najaarsmarkten van vrije scholen, Waldorffestival en 1x per maand op de Biologische Boerenmarkt in Frederiksoord.</p>
          <p class="card-text">De Wolstertruck staat regelmatig op de mini-markt van de vrije school, de Toermalijn in Meppel. Samen met een boekentafel, stenen en mineralen en honing, van 13.45 tot 14.45uur.</p>
          <p class="card-text">In Frederiksoord is het meestal de 3de zaterdag van de maand, maar dit is ook afhankelijk van het weer. Als het die dag erg hard gaat waaien of regenen, dan wordt het een week later. Dit wordt gecommuniceerd via de Facebookpagina van De Wolster en de Biologische Boerenmarkt.</p>
          {/* </Row> */}
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Persoonlijke service</h4>
          <p class="card-text">Je bent van harte welkom in de winkel. Hier kun je onder het genot van een kopje koffie of thee op je gemak alles bekijken, voelen en passen. Indien je vragen hebt over de collectie beantwoorden we deze graag. Ook voorzien we je, waar gewenst, van een persoonlijk en eerlijk advies. </p>
          <h4 class="card-title py-3" style={{fontSize:'150%'}}>Neem contact met ons op</h4>
          <p class="card-text">Ben je op zoek naar duurzame herenkleding? Dan kun je uitstekend terecht bij De Wolster. Heb je vragen over het aanbod of wil je graag een afspraak maken? Neem dan vrijblijvend contact op. Hiervoor kun je bellen naar 06-12574637 of een bericht achterlaten in het <Link to='/contact' >contactformulier</Link> op deze website. We staan je zo snel mogelijk te woord. Graag tot ziens bij De Wolster!</p>
        </div>
      </div>
    </div>
    </div>
  )
}

export default OverDewolster
