import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleXmark
} from '@fortawesome/free-solid-svg-icons'

  const ImageGallery = ({galleryImages}) => {
  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setopenModal] = useState(false)

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setopenModal(true)
  }

  const handleCloseModal = () => {
    setopenModal(false)
  }

  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber(galleryImages.length -1) 
    : setSlideNumber(slideNumber-1)
  }

  const nextSlide = () => {
    slideNumber === galleryImages.length -1 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber+1)

  }
  

  return (
    <div>
        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" /> */}

        {openModal && 
            <div className='sliderWrap'>
                {galleryImages.length > 1 && <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide}/>}
                <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal} />
                {galleryImages.length > 1 && <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />}
                <div className='fullScreenImage'>
                    <img loading='lazy' src={galleryImages[slideNumber].img} alt='' />
                </div>
            </div>
        }

        

        <div className='galleryWrap pb-3'>
            {
        galleryImages && galleryImages.map((slide, index) => {
            return(
                <div 
                className='galleryimg' 
                key={index}
                onClick={ () => handleOpenModal(index)}
                >
                    <img src={slide.img} alt='' />
                    
                    </div>
            )
        })
        }
        </div>
    </div>
  )
}

export default ImageGallery
