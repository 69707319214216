import React from 'react'
import { Card } from 'react-bootstrap'

function Event({ event }) {
  return (
    <div>
      {/* <p>{event.title}</p>
      {event.description ? <Card.Text>{event.description}</Card.Text> : null}
      {event.start_date ? <Card.Text>{event.start_date}{event.endDate ? ' - ' + event.end_date : ''}</Card.Text> : null}
      {event.image ? <Card.Img style={{ width: '100%' }} src={event.image}></Card.Img> : null} */}
    
    <Card className='border-secondary my-3 py-1 rounded'>
      <Card.Body>
        <Card.Title>{event.title}</Card.Title>
        {event.description ? <Card.Text>{event.description}</Card.Text> : null}
        {event.start_date ? <Card.Text>{event.start_date.split('-')[2]}/{event.start_date.split('-')[1]}/{event.start_date.split('-')[0]}{event.endDate ? ' - ' + event.end_date.split('-')[2] + '/' + event.end_date.split('-')[1] + '/' + event.end_date.split('-')[0] : ''}</Card.Text> : null}
        {event.image ? <Card.Img style={{ width: '100%' }} src={event.image}></Card.Img> : null}
      </Card.Body>
    </Card>
    </div>
  )
}

export default Event
