import React, { useEffect } from 'react'
import ImageGallery from '../components/ImageGallery'

function WollenProducten() {

  useEffect(() => {
    //  scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const galleryImages = [
    {
      img: 'https://dewolster.nl/static/images/WollenProductenGallery1.jpg',
    },
    {
      img: 'https://dewolster.nl/static/images/WollenProductenGallery2.jpg',
    }
  ]

  return (
    <div>
      <div>
        <div class="card mb-3 border-light">
          <div class="card-body text-center" style={{fontSize:'125%'}}>
            <h4 class="card-title py-3" style={{fontSize:'150%'}}>Diverse ecologische producten</h4>
            <div class='row justify-content-md-center'>
              <div class="col-sm-8">
                <p class="card-text">Bij De Wolster kun je niet alleen terecht voor een ruim assortiment aan dames-, heren- en kinderkleding, maar je vindt er ook een gevarieerd aanbod in andere producten en accessoires van wol. De accessoires zijn net als de kleding duurzaam geproduceerd, veelal met het GOTS-keurmerk. De schapenvachten zijn ecologisch gelooid in Europese looierijen en zijn afkomstig van particuliere boeren. Ben je nieuwsgierig geworden naar het aanbod van wollen producten en accessoires? Kom dan gezellig even langs! </p>
              </div>
                <img loading='lazy' className='wrap-text-around-image mx-3' src='https://dewolster.nl/static/images/WollenProductenImage1.jpg'/>
              <div class="col-sm-4">
                  {/* <ImageGallery galleryImages={[{img: 'https://dewolster.nl/static/images/WollenProductenImage1.jpg'}]}/> */}
              </div>
            </div>
            <h4 class="card-title py-3" style={{fontSize:'150%'}}>Ruim en divers assortiment</h4>
            <p class="card-text">Het is heel mooi om steeds vanuit de interactie en de wensen van de klanten te kijken wat mooie, nieuwe aanvullingen zouden kunnen zijn voor De Wolster. Zo hebben we in de afgelopen jaren al een aantal prachtige, duurzame dingen gevonden die goed aansluiten bij de collecties. Zo hebben we steeds meer keuze in zachte, wollen babydekens voor zowel de wieg als in een ledikant. Maar er zijn ook grote, zachte dekens waar je heerlijk onder kunt kruipen op de bank met het hele gezin. Lamsvachtjes voor baby’s, voetenzakken gevoerd met lamsvacht voor in de wandelwagen en grote, dikke vachten in allerlei soorten voor op de bank, in de tuin, mee op vakantie of om lekker op te slapen. Er is een ruime, kleurige keuze in wollen sokken, dik, dun, lang, kort, met noppen of zonder en ook met katoen of linnen. Dit voor volwassenen en kinderen. Slofjes zijn er voor baby’s, pantoffels voor volwassen, gevilt of gevoerd met lamsvacht.</p>
            <ImageGallery galleryImages={galleryImages} />
            <p class="card-text">De accessoires passen perfect bij de jurkjes, vesten en al het andere moois wat er bij De Wolster te vinden is. Denk o.a. aan polswarmers van Reiff en Jalfe, beenwarmers van Hirsch en Decolores, wollen sjaals van Heidekönigin en Jalfe en mutsen van Aran en Reiff.</p>
            <h4 class="card-title py-3" style={{fontSize:'150%'}}>Persoonlijke service</h4>
            <p class="card-text">Je bent van harte welkom in de winkel. Hier kun je onder het genot van een kopje koffie of thee op je gemak alles bekijken, voelen en passen. Indien je vragen hebt over de collectie beantwoorden we deze graag. Ook voorzien we je, waar gewenst, van een persoonlijk en eerlijk advies. </p>
            <h4 class="card-title py-3" style={{fontSize:'150%'}}>Neem contact met ons op</h4>
            <p class="card-text">Ben je op zoek naar duurzame herenkleding? Dan kun je uitstekend terecht bij De Wolster. Heb je vragen over het aanbod of wil je graag een afspraak maken? Neem dan vrijblijvend contact op. Hiervoor kun je bellen naar 06-12574637 of een bericht achterlaten in het contactformulier op deze website. We staan je zo snel mogelijk te woord. Graag tot ziens bij De Wolster!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WollenProducten
