import React from 'react'
import { Col, Row } from 'react-bootstrap'

function Banner({ imgL, imgM, imgR }) {
  return (
      <Row className="justify-content-md-center Banner">
        <Col className="justify-content-center px-0">
            <img loading='lazy' className='banner-img' src={imgL} />
        </Col>
        <Col className="justify-content-center px-0">
            <img loading='lazy' className='banner-img' src={imgM} />
        </Col>
        <Col className="justify-content-center px-0">
            <img loading='lazy' className='banner-img' src={imgR} />
        </Col>
      </Row>
  )
}

export default Banner
